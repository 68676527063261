// @flow
import * as React from 'react';

type ButtonFocusCatcherProps = {
  onButtonClick: (?SyntheticEvent<HTMLElement>) => void,
  className: ?string,
  /**
   * An html element type string. Default is 'div'
   */
  as: ?string,
  children: React.Node,
}

// Used To prevent button's focus styles from appearing when clicking the button with a mouse.
// The focus styles will still appear when navigating to the button via keyboard.
// This does not handle any styling. It is the component user's responsibility to
// make the "catcher" fill up the entire button's realestate for this hacky hack to work,
// as well as to disable the default outline on the "catcher" element.
function ButtonFocusCatcher({ as, onButtonClick, className, children, }: ButtonFocusCatcherProps) {
  const Tag = as || 'div';
  return (
    <Tag
      tabIndex="-1"
      className={className}
      // prevent scroll on page when space or shift+space is pressed
      onKeyDown={evt => {
        if (evt.key === ' ') {
          onButtonClick(evt);
          evt.preventDefault();
        }
      }}
    >
      {children}
    </Tag>
  );
}

ButtonFocusCatcher.defaultProps = { as: 'div', };

export default ButtonFocusCatcher;

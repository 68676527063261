import React from 'react';
import { loadStates, } from './audioPlayerConsts';
import { formatTime, } from './audioPlayerUtils';

const noop = () => {};

const defaultValue = {
  audio: null,
  loadState: loadStates.notLoading,
  currentTime: 0,
  timeElapsed: formatTime(0),
  displayedDuration: formatTime(-1),
  playbackRate: 1,
  isBuffering: false,
  isPlaying: false,
  isMuted: false,
  initialState: true,

  skip: noop,
  onScrub: noop,
  onProgressBarClick: noop,
  onPlayButtonClick: noop,
  onPlaybackRateChangeRequested: noop,
  getNextPlaybackRate: noop,
  toggleMute: noop,
};

export const audioPlayerContext = React.createContext(defaultValue);
const useAudioPlayerContext = () => React.useContext(audioPlayerContext);

export default useAudioPlayerContext;

/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  rgba,
  parseComponentProp,
  parseStyleProps,
  type ComponentPropResponsiveObject,
  type StyleProps,
} from '@haaretz/htz-css-tools';
import IconPlay from '../../Icon/icons/IconPlay';
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';
import ButtonFocusCatcher from './ButtonFocusCatcher';
import { loadStates, } from '../audioPlayerConsts';
import { setDiameter, } from '../audioPlayerUtils';
import useAudioPlayerContext from '../useAudioPlayerContext';
import useGetComponent from '../../../hooks/GetComponentContext/useGetComponent';

type PlayButtonProps = {
  /** named color from theme */
  color: string | [ string, ] | [ string, string, ],
  /** named color from theme */
  bgColor: string | [ string, ] | [ string, string, ],
  diameter: string | number | ComponentPropResponsiveObject<string | number>,
  playIconSize: number | ComponentPropResponsiveObject<number>[],
  loaderIconSize: number | ComponentPropResponsiveObject<number>[],
  gridArea: ?string,
  miscStyles: ?StyleProps,
  pauseBarHeight: string | number | ComponentPropResponsiveObject<string | number>,
  pauseBarWidth: string | number | ComponentPropResponsiveObject<string | number>,
  pauseBarGap: string | number | ComponentPropResponsiveObject<string | number>,
};

const pauseBarStyle = ({
  theme,
  isBuffering,
  color,
  pauseBarHeight,
  pauseBarWidth,
  pauseBarGap,
  isLeftBar = false,
}) => ({
  content: '""',
  backgroundColor: theme.color(...(Array.isArray(color) ? color : [ color, ])),
  borderRadius: '1px',
  animationDuration: isBuffering ? '0.2s' : undefined,
  animationDelay: isBuffering ? '0.2s' : '0',
  animationFillMode: 'both',
  animationName: {
    '0%': { opacity: 0, },
    '100%': { opacity: 1, },
  },
  extend: parseStyleProps(
    {
      height: pauseBarHeight,
      width: pauseBarWidth,
      ...(isLeftBar ? {
        marginRight: pauseBarGap,
      } : {}),
    },
    theme.mq,
    theme.type
  ),
});

const playButtonStyle = ({
  theme,
  bgColor,
  diameter,
  miscStyles,
  isBuffering,
  isPlaying,
  gridArea,
}) => {
  const hasBufferingAnimation = isBuffering && isPlaying;
  const backgroundColor = theme.color(...(Array.isArray(bgColor) ? bgColor : [ bgColor, ]));
  return {
    ':-moz-focusring': { outline: `2px dotted ${theme.color('audioPlayer', 'mozFocusRing')}`, },
    direction: 'rtl',
    position: 'relative',
    backgroundColor,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animationDuration: '1.3s',
    animationDelay: '0.6s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-out',
    transitionProperty: 'transform',
    transitionDuration: '0.4s',
    transitionDelay: isBuffering ? '0.2s' : null,
    ...(hasBufferingAnimation ? {
      transform: 'scale(0.4)',
      animationName: {
        '0%': {
          boxShadow: `0 0 0 0px ${rgba(backgroundColor, 1) || ''}`,
        },
        '10%': {
          boxShadow: `0 0 0 5px ${rgba(backgroundColor, 0.9) || ''}`,
        },
        '100%': {
          boxShadow: `0 0 0 50px ${rgba(backgroundColor, 0) || ''}`,
        },
      },
    } : {}),
    gridArea,
    extend: [
      parseComponentProp(
        'diameter',
        diameter,
        theme.mq,
        setDiameter
      ),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
};

const playButtonInnerStyle = ({
  theme,
  isPlaying,
  isBuffering,
  color,
  pauseBarHeight,
  pauseBarWidth,
  pauseBarGap,
  pauseBarMiscStyles,
}) => ({
  ':-moz-focusring': { outline: 'none', },
  outline: 'none',
  position: 'absolute',
  top: '0',
  bottom: '0',
  start: '0',
  end: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transitionProperty: 'opacity',
  transitionDuration: isBuffering ? '0' : '0.2s',
  transitionDelay: isBuffering && isPlaying ? '0.2s' : null,
  opacity: isBuffering && isPlaying ? 0 : 1,
  ...(isPlaying
    ? {
      ':before': pauseBarStyle({
        isBuffering,
        theme,
        color,
        pauseBarHeight,
        pauseBarWidth,
        pauseBarGap,
        pauseBarMiscStyles,
        isLeftBar: false,
      }),
      ':after': pauseBarStyle({
        isBuffering,
        theme,
        color,
        pauseBarHeight,
        pauseBarWidth,
        pauseBarGap,
        pauseBarMiscStyles,
        isLeftBar: true,
      }),
    }
    : {}),
});

const shouldShowLoader = loadState => (
  [ loadStates.notLoading, ].includes(loadState)
);

PlayButton.defaultProps = {
  color: [ 'audioPlayer', 'playBtn', ],
  bgColor: [ 'audioPlayer', 'playBg', ],
  diameter: '47px',
  playIconSize: [
    { until: 'm', value: 3.5, },
    { from: 'm', until: 'xl', value: 3.5, },
    { from: 'xl', value: 3, },
  ],
  loaderIconSize: [ { until: 'xl', value: 7, }, { from: 'xl', value: 6, }, ],
  gridArea: undefined,
  miscStyles: null,
  pauseBarHeight: '20px',
  pauseBarWidth: '6px',
  pauseBarGap: '4px',
};

function PlayButton({
  color,
  bgColor,
  diameter,
  playIconSize,
  loaderIconSize,
  gridArea,
  miscStyles,
  pauseBarHeight,
  pauseBarWidth,
  pauseBarGap,
}: PlayButtonProps) {
  const getComponent = useGetComponent();
  const {
    loadState,
    isPlaying,
    isBuffering,
    onPlayButtonClick,
  } = useAudioPlayerContext();

  const { css, theme, } = useFela({
    isPlaying,
    isBuffering,
    color,
    bgColor,
    diameter,
    gridArea,
    miscStyles,
    pauseBarHeight,
    pauseBarWidth,
    pauseBarGap,
  });

  const LoaderIcon = getComponent('loaderIcon');
  return (
    <button
      type="button"
      onClick={onPlayButtonClick}
      className={css(playButtonStyle)}
      data-test="playButton"
    >
      <ButtonFocusCatcher
        onButtonClick={onPlayButtonClick}
        className={css(playButtonInnerStyle)}
      >
        {shouldShowLoader(loadState) ? (
          <LoaderIcon
            aria-hidden
            size={loaderIconSize}
            color={color}
          />
        ) : isPlaying ? null : (
          <IconPlay
            aria-hidden
            size={playIconSize}
            color={color}
            miscStyles={{ transform: 'translateX(10%)', }}
          />
        )}
        <VisuallyHidden>{theme.audioPlayerI18n[isPlaying ? 'pause' : 'play']}</VisuallyHidden>
      </ButtonFocusCatcher>
    </button>
  );
}

export default PlayButton;

// @flow
import * as React from 'react';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import { useEventTracker, } from '../../utils/EventTracker';
import OmnyPodcast from './OmnyPodcast/OmnyPodcast';
import AudioPlayerActionsProvider from './AudioPlayerActionsProvider';

type AudioPlayerWithActionsProps = {
  fileUrl: string,
  loadAfterFirstClick: boolean,
  contentName: string,
  channelName: string,
  isOmny: ?boolean,
  miscStyles: ?StyleProps,
  sendBiImpression: boolean,
  articleType: ?string,
  wrapperRef?: ?({ current: null | HTMLDivElement, } | (?HTMLDivElement) => void),

  /**
   * Children should include audio player elements (a play button, at the very least).
   * Player elements may be imported from the `HtzAudioPlayer` directory,
   * either from the main index.js file or from the `audioPlayerElements` folder.
   * If need be, it should be possible to implement custom player elements by accessing
   * the player context via `useAudioPlayerContext`.
   *
   * When rendering the audio player component without any children, the default player skin will be used.
   */
  children: ?React.Node,
};

AudioPlayerWithActions.defaultProps = {
  isOmny: false,
  loadAfterFirstClick: true,
  miscStyles: null,
  sendBiImpression: false,
  articleType: null,
  wrapperRef: null,
};

function AudioPlayerWithActions({
  fileUrl,
  contentName,
  isOmny,
  loadAfterFirstClick,
  miscStyles,
  children,
  sendBiImpression,
  articleType,
  wrapperRef,
  channelName,
}: AudioPlayerWithActionsProps): React.Node {
 const { biImpression, biAction, } = useEventTracker();
  const podcastBIAction = React.useCallback(
    (campaignDetails, additionalInfo) => {
      biAction && biAction({
        actionCode: 161,
        feature: isOmny ? 'podcast player' : 'audio embed',
        featureType: 'Content',
        campaignName: contentName,
        campaignDetails,
        writerName: channelName,
        ...(articleType ? { articleType, } : {}),
        ...(additionalInfo ? { additionalInfo, } : {}),
      });
    },
    [ biAction, isOmny, contentName, articleType, ]
  );

  React.useEffect(() => {
    if (sendBiImpression) {
      biImpression && biImpression({
        feature: isOmny ? 'podcast player' : 'audio embed',
        featureType: 'Content',
        campaignName: contentName,
        writerName: channelName,
        ...(articleType ? { articleType, } : {}),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ sendBiImpression, ]);

  const PlayerWrapper = isOmny ? OmnyPodcast : AudioPlayerActionsProvider;

  return (
    <PlayerWrapper
      fileUrl={fileUrl}
      loadAfterFirstClick={loadAfterFirstClick}
      podcastBIAction={podcastBIAction}
      miscStyles={miscStyles}
      wrapperRef={wrapperRef}
    >
      {children}
    </PlayerWrapper>
  );
}

export default AudioPlayerWithActions;

/* noOverWrite */
// Using SVG transform to flip the icon path, as it yields better results than
// CSS transform on the SVG element.

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import iconStyle from '../iconStyle';
import { iconPropTypes, iconDefaultProps, } from '../iconPropTypes';
import { attrsPropType, } from '../../../propTypes/attrsPropType';

export default function IconSkip({ size, fill, color, attrs, miscStyles, isBack, text, textAttrs, ...props }) {
  const className = useFela({ size, fill, color, miscStyles, }).css(iconStyle);

  return (
    <svg width="0.8695652173913043em" height="1em" viewBox="0 0 20 23" className={className} {...props} {...attrs}>
      <g transform={isBack ? 'translate(20) scale(-1, 1)' : null}>
        <path fill="currentColor" d="M12.6 3.5v2.8l4.725-3.15L12.6 0v2.8L8.4 0v2.224C3.64 2.979 0 7.102 0 12.075c0 5.509 4.466 9.975 9.975 9.975 5.509 0 9.975-4.466 9.975-9.975 0-.176-.005-.351-.014-.525H18.36A8.4 8.4 0 118.4 3.822V6.3l4.2-2.8z" />
      </g>
      {text && <text fill="currentColor" x="50%" y="56%" fontSize="9" fontWeight="700" textAnchor="middle" dominantBaseline="middle" {...textAttrs}>{text}</text>}
    </svg>
  );
}

IconSkip.propTypes = {
  ...iconPropTypes,
  isBack: PropTypes.bool,
  text: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, ]),
  textAttrs: attrsPropType,
};
IconSkip.defaultProps = {
  ...iconDefaultProps,
  isBack: false,
  text: null,
  textAttrs: undefined,
};

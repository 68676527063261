/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, type StyleProps, } from '@haaretz/htz-css-tools';
import useAudioPlayerContext from '../useAudioPlayerContext';

type AudioDurationProps = {
  miscStyles: ?StyleProps,
  disableGridArea: boolean,
};

AudioDuration.defaultProps = {
  miscStyles: null,
  disableGridArea: false,
};

function AudioDuration({ disableGridArea, miscStyles, }: AudioDurationProps): React.Node {
  const { displayedDuration, } = useAudioPlayerContext();
  const { css, theme, } = useFela();

  return (
    <div
      className={css({
        gridArea: disableGridArea ? null : 'duration',
        lineHeight: 1,
        extend: [
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      {displayedDuration}
    </div>
  );
}

export default AudioDuration;

/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  parseStyleProps,
  type ComponentPropResponsiveObject,
  type StyleProps,
} from '@haaretz/htz-css-tools';
import IconSkip from '../../Icon/icons/IconSkip';
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';
import ButtonFocusCatcher from './ButtonFocusCatcher';
import useAudioPlayerContext from '../useAudioPlayerContext';

type SkipButtonProps = {
  skipBy: number,
  size: number | ComponentPropResponsiveObject<number>[],
  textAttrs: ?Object,
  gridArea: ?string,
  miscStyles: ?StyleProps,
  iconMiscStyles: ?StyleProps,
};

const skipButtonStyle = ({ theme, gridArea, miscStyles, }) => ({
  ':-moz-focusring': { outline: `2px dotted ${theme.color('audioPlayer', 'mozFocusRing')}`, },
  position: 'relative',
  alignSelf: 'center',
  gridArea,
  extend: [
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

const skipButtonInnerStyle = {
  ':-moz-focusring': { outline: 'none', },
  outline: 'none',
};

SkipButton.defaultProps = {
  skipBy: 15,
  size: 3.8,
  textAttrs: undefined,
  gridArea: undefined,
  miscStyles: null,
  iconMiscStyles: null,
};

function SkipButton({
  skipBy,
  size,
  textAttrs,
  gridArea,
  miscStyles,
  iconMiscStyles,
}: SkipButtonProps) {
  const { css, theme, } = useFela({ gridArea, miscStyles, });
  const { skip, } = useAudioPlayerContext();
  const onClick = () => skip(skipBy);

  return (
    <button
      type="button"
      onClick={onClick}
      className={css(skipButtonStyle)}
      data-test={`skipButton${skipBy || 15}`}
    >
      <ButtonFocusCatcher onButtonClick={onClick} className={css(skipButtonInnerStyle)}>
        <IconSkip
          aria-hidden
          size={size}
          isBack={skipBy < 0}
          text={Math.abs(skipBy).toString()}
          textAttrs={textAttrs}
          miscStyles={iconMiscStyles}
        />
        <VisuallyHidden>{theme.audioPlayerI18n.skip(skipBy)}</VisuallyHidden>
      </ButtonFocusCatcher>
    </button>
  );
}

export default SkipButton;

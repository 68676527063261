/* global window */
// @flow
import * as React from 'react';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import HtzAudioPlayer from '../HtzAudioPlayer/HtzAudioPlayer';

type onSkipArgType = { from: number, to: number, isPlaying: boolean, };

type onPlayPauseArgType = { pos: number, };

type onUnmountArgType = { pos: number, isPlaying: boolean, isUnloading: boolean, };

type AudioPlayerActionsProviderProps = {
  fileUrl: string,
  podcastBIAction: (string, ?Object) => void,
  omnyOnPlay: ?(onPlayPauseArgType => boolean),
  omnyOnPause: ?(onPlayPauseArgType => void),
  omnyOnSkip: ?(onSkipArgType => void),
  omnyOnPlaybackEnd: ?(onPlayPauseArgType => void),
  omnyOnUnmount: ?(onUnmountArgType => void),
  loadAfterFirstClick: ?boolean,
  miscStyles: ?StyleProps,
  wrapperRef?: ?({ current: null | HTMLDivElement, } | (?HTMLDivElement) => void),
  children: ?React.Node,
};

AudioPlayerActionsProvider.defaultProps = {
  omnyOnPlay: null,
  omnyOnPause: null,
  omnyOnSkip: null,
  omnyOnPlaybackEnd: null,
  omnyOnUnmount: null,
  miscStyles: null,
  loadAfterFirstClick: undefined,
  wrapperRef: null,
};

const HTZ_UTIL = '__HTZ';

function AudioPlayerActionsProvider({
  fileUrl,
  podcastBIAction,
  omnyOnPlay,
  omnyOnPause,
  omnyOnSkip,
  omnyOnPlaybackEnd,
  omnyOnUnmount,
  loadAfterFirstClick,
  miscStyles,
  wrapperRef,
  children,
}: AudioPlayerActionsProviderProps): React.Node {
  const onPlay = React.useCallback(
    // $FlowFixMe
    ({ pos, duration, }: onPlayPauseArgType) => {
      const startOfOmnySession = omnyOnPlay && omnyOnPlay({ pos, duration, });
      podcastBIAction('play', { pos, duration, ...(omnyOnPlay ? { startOfOmnySession, } : {}), });
      if (window[HTZ_UTIL] && typeof window[HTZ_UTIL].cancelRefresh === 'function') {
        window[HTZ_UTIL].cancelRefresh();
      }
    },
    [ omnyOnPlay, podcastBIAction, ]
  );

  const onPause = React.useCallback(
    // $FlowFixMe
    ({ pos, duration, }: onPlayPauseArgType) => {
      omnyOnPause && omnyOnPause({ pos, duration, });
      podcastBIAction('pause', { pos, duration, });
    },
    [ omnyOnPause, podcastBIAction, ]
  );

  const onSkip = React.useCallback(
    // $FlowFixMe
    ({ from, to, duration, isPlaying, byKeyboard, }) => {
      omnyOnSkip && omnyOnSkip({ from, to, isPlaying, });
      const secs = Math.round(to - from);
      podcastBIAction(
        `skip ${Math.abs(secs)} sec ${secs < 0 ? 'backwards' : 'forwards'}`,
        { from, to, duration, whilePlaying: isPlaying, byKeyboard, }
      );
    },
    [ omnyOnSkip, podcastBIAction, ]
  );

  const onScrub = React.useCallback(
    // $FlowFixMe
    ({ from, to, duration, isPlaying, }: onSkipArgType) => {
      omnyOnSkip && omnyOnSkip({ from, to, isPlaying, });
      podcastBIAction('scroll bar', { from, to, duration, whilePlaying: isPlaying, actionType: 'scroll', });
    },
    [ omnyOnSkip, podcastBIAction, ]
  );

  const onProgressBarClick = React.useCallback(
    // $FlowFixMe
    ({ from, to, duration, isPlaying, }: onSkipArgType) => {
      omnyOnSkip && omnyOnSkip({ from, to, isPlaying, });
      podcastBIAction('scroll bar', { from, to, duration, whilePlaying: isPlaying, actionType: 'click', });
    },
    [ omnyOnSkip, podcastBIAction, ]
  );

  const onPlaybackRateChange = React.useCallback(
    // $FlowFixMe
    ({ from, to, }) => {
      podcastBIAction(`speed - ${to}X`);
    },
    [ podcastBIAction, ]
  );

  const onPlaybackEnd = React.useCallback(
    // $FlowFixMe
    ({ pos, }: onPlayPauseArgType) => {
      omnyOnPlaybackEnd && omnyOnPlaybackEnd({ pos, });
      podcastBIAction('playback end', { pos, });
    },
    [ omnyOnPlaybackEnd, podcastBIAction, ]
  );

  const onUnmount = React.useCallback(
    // $FlowFixMe
    ({ pos, duration, isPlaying, isUnloading, }: onUnmountArgType) => {
      omnyOnUnmount && omnyOnUnmount({ pos, duration, isPlaying, isUnloading, });
      if (isPlaying) podcastBIAction('pause', { pos, duration, });
    },
    [ omnyOnUnmount, podcastBIAction, ]
  );

  return (
    <HtzAudioPlayer
      fileUrl={fileUrl}
      onSkip={onSkip}
      onPlay={onPlay}
      onPause={onPause}
      onScrub={onScrub}
      onProgressBarClick={onProgressBarClick}
      onPlaybackEnd={onPlaybackEnd}
      onPlaybackRateChange={onPlaybackRateChange}
      onUnmount={onUnmount}
      loadAfterFirstClick={loadAfterFirstClick}
      miscStyles={miscStyles}
      wrapperRef={wrapperRef}
    >
      {children}
    </HtzAudioPlayer>
  );
}

export default AudioPlayerActionsProvider;

/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, type StyleProps, type ComponentPropResponsiveObject, } from '@haaretz/htz-css-tools';
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';
import ButtonFocusCatcher from './ButtonFocusCatcher';
import useAudioPlayerContext from '../useAudioPlayerContext';


type PlaybackRateButtonProps = {
  timesSymbol: string | number | React.Element<any>,
  alignItems: string | ComponentPropResponsiveObject<string>[],
  justifyContent: string | ComponentPropResponsiveObject<string>[],
  miscStyles: ?StyleProps,
};

const playbackRateButtonStyle = ({ theme, miscStyles, }) => ({
  ':-moz-focusring': { outline: `2px dotted ${theme.color('audioPlayer', 'mozFocusRing')}`, },
  display: 'grid',
  gridArea: 'rate',
  fontWeight: 700,
  extend: [
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

PlaybackRateButton.defaultProps = {
  timesSymbol: 'x',
  miscStyles: null,
  alignItems: 'center',
  justifyContent: 'center',
};

function PlaybackRateButton({
  timesSymbol,
  miscStyles,
  alignItems,
  justifyContent,
}: PlaybackRateButtonProps) {
  const { css, theme, } = useFela({ miscStyles, });
  const {
    onPlaybackRateChangeRequested,
    getNextPlaybackRate,
    playbackRate,
  } = useAudioPlayerContext();

  return (
    <button type="button" className={css(playbackRateButtonStyle)} onClick={onPlaybackRateChangeRequested}>
      <ButtonFocusCatcher
        onButtonClick={onPlaybackRateChangeRequested}
        className={css({
          outline: 'none',
          height: '100%',
          width: '100%',
          display: 'grid',
          extend: parseStyleProps({
            alignItems,
            justifyContent,
          }, theme.mq, theme.type),
        })}
      >
        {playbackRate}
        {timesSymbol}
        <VisuallyHidden>
          {theme.audioPlayerI18n.changeRate(playbackRate, getNextPlaybackRate())}
        </VisuallyHidden>
      </ButtonFocusCatcher>
    </button>
  );
}

export default PlaybackRateButton;

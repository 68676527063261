/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  rgba,
  parseComponentProp,
  parseStyleProps,
  type ComponentPropResponsiveObject,
  type StyleProps,
} from '@haaretz/htz-css-tools';
import throttleFn from '../../../utils/throttleFn';

import useAudioPlayercontext from '../useAudioPlayerContext';
import { formatTime, setDiameter, } from '../audioPlayerUtils';


type AudioProgressControllerProps = {
  /**
   * Named color from theme
   */
  railColor: string | [ string, ] | [ string, string, ],
  /**
   * Named color from theme
   */
  progressColor: string | [ string, ] | [ string, string, ],
  sliderDiameter: string | number | ComponentPropResponsiveObject<string | number>[],
  disableGridArea: boolean,
  miscStyles: ?StyleProps,
}

const wrapperStyle = ({ disableGridArea, theme, miscStyles, }) => ({
  zIndex: 1,
  gridArea: disableGridArea ? null : 'progress',
  position: 'relative',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  alignSelf: 'center',
  extend: [
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.color) : []),
  ],
});

const railStyle = ({ theme, railColor, }) => ({
  height: '4px',
  borderRadius: '2rem',
  overflow: 'hidden',
  backgroundColor: theme.color(...(Array.isArray(railColor) ? railColor : [ railColor, ])),
});

const progressIndicatorStyle = ({
  theme,
  seeking,
  handlingClick,
  isBuffering,
  progressColor,
}) => ({
  backgroundColor: theme.color(
    ...(Array.isArray(progressColor) ? progressColor : [ progressColor, ])),
  height: '100%',
  transitionTimingFunction: 'linear',
  transitionProperty: 'transform',
  transitionDuration: seeking ? undefined : handlingClick || isBuffering ? '110ms' : '550ms',
  transformOrigin: 'left',
});

const sliderClickAreaStyle = ({ theme, seeking, handlingClick, isBuffering, }) => ({
  ':-moz-focusring': { outline: `2px dotted ${theme.color('audioPlayer', 'mozFocusRing')}`, },
  cursor: 'pointer',
  touchAction: 'none',
  position: 'absolute',
  left: '-12px',
  top: 'calc(50% - 12px)',
  width: '24px',
  height: '24px',
  transitionTimingFunction: 'linear',
  transitionProperty: 'transform',
  transitionDuration: seeking ? undefined : handlingClick || isBuffering ? '110ms' : '550ms',
});

const sliderOuterStyle = {
  ':-moz-focusring': { outline: 'none', },
  outline: 'none',
  position: 'absolute',
  top: '0',
  start: '0',
  bottom: '0',
  end: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const sliderInnerStyle = ({ seeking, progressColor, sliderDiameter, theme, }) => ({
  backgroundColor: theme.color(
    ...(Array.isArray(progressColor) ? progressColor : [ progressColor, ])
  ),
  maxWidth: '24px',
  maxHeight: '24px',
  borderRadius: '50%',
  transition: 'all 0.4s',
  ':hover': seeking ? {} : {
    transform: 'scale(1.4)',
  },
  ...(seeking && {
    transform: 'scale(0.7)',
    boxShadow: `0 0 0 6rem ${theme.color(...(Array.isArray(progressColor) ? progressColor : [ progressColor, ]), 0.3) || ''}`,
  }),
  extend: [
    parseComponentProp(
      'sliderDiameter',
      sliderDiameter,
      theme.mq,
      setDiameter
    ),
  ],
});

const keyDirection = {
  ArrowLeft: -1,
  ArrowRight: 1,
  ArrowDown: -1,
  ArrowUp: 1,
  PageDown: -3,
  PageUp: 3,
  End: Infinity,
  Home: -Infinity,
};

const SKIP_BY = 10;

AudioProgressController.defaultProps = {
  railColor: [ 'audioPlayer', 'rail', ],
  progressColor: [ 'audioPlayer', 'progressBar', ],
  sliderDiameter: '10px',
  disableGridArea: false,
  miscStyles: null,
};

function AudioProgressController({
  railColor,
  progressColor,
  sliderDiameter,
  disableGridArea,
  miscStyles,
}: AudioProgressControllerProps) {
  const {
    audio,
    displayedDuration,
    timeElapsed,
    isPlaying,
    isBuffering,
    skip,
    onScrub,
    onProgressBarClick,
  } = useAudioPlayercontext();
  const [ timerPosition, setTimerPosition, ] = React.useState(0);
  const [ seekingTime, setSeekingTime, ] = React.useState(null);
  const [ seeking, setSeeking, ] = React.useState(false);
  const [ handlingClick, setHandlingClick, ] = React.useState(false);
  const [ barWidth, setBarWidth, ] = React.useState(0);
  const wrapperRef = React.useRef();
  const { css, theme, } = useFela({
    seeking,
    handlingClick,
    isBuffering,
    railColor,
    progressColor,
    sliderDiameter,
    disableGridArea,
    miscStyles,
  });

  const ariaValueNow = seekingTime || timeElapsed;
  const ariaValueText = theme.audioPlayerI18n.ariaValueText(ariaValueNow, displayedDuration);

  const onBarClick = React.useCallback(
    // $FlowFixMe
    ({ clientX: x, }: SyntheticMouseEvent<HTMLDivElement>) => {
      if (!audio || !wrapperRef.current || !audio.duration) return;
      setHandlingClick(true);
      const rect = wrapperRef.current.getBoundingClientRect();
      const relativeX = x - rect.x;
      const nextTimerPosition = relativeX / rect.width;
      setTimerPosition(nextTimerPosition);
      const from = audio.currentTime;
      // eslint-disable-next-line no-param-reassign
      audio.currentTime = audio.duration * nextTimerPosition;
      onProgressBarClick && onProgressBarClick(
        { from, to: audio.currentTime, duration: audio.duration, isPlaying, }
      );
    },
    [ audio, onProgressBarClick, isPlaying, ]
  );

  const onKeyDown = React.useCallback(
    // $FlowFixMe
    (evt: SyntheticKeyboardEvent<HTMLDivElement>) => {
      if (keyDirection[evt.key]) {
        evt.preventDefault();
        skip(SKIP_BY * keyDirection[evt.key], true);
      }
    },
    [ skip, ]
  );

  const onMouseDown = React.useCallback(() => {
    if (!audio || !wrapperRef.current || !audio.duration) return;
    let pos = audio.currentTime / audio.duration;
    const from = audio.currentTime;
    setSeeking(true);
    const rect = wrapperRef.current.getBoundingClientRect();
    const handleMouseMove = evt => {
      evt.type === 'touchmove' && evt.preventDefault();
      const x = evt.type === 'touchmove' ? evt.touches[0].clientX : evt.clientX;
      if (x < rect.x) setTimerPosition(0);
      else if (x > rect.x + rect.width) setTimerPosition(1);
      else {
        const relativeX = x - rect.x;
        pos = relativeX / rect.width;
        setTimerPosition(pos);
        setSeekingTime(formatTime(audio.duration * pos));
      }
    };
    const handleMouseUp = () => {
      setSeeking(false);
      setSeekingTime(null);
      // eslint-disable-next-line no-param-reassign
      audio.currentTime = audio.duration * pos;
      onScrub && onScrub({ from, to: audio.currentTime, duration: audio.duration, isPlaying, });

      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove, { passive: false, });
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleMouseMove, { passive: false, });
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);
  }, [ audio, onScrub, isPlaying, ]);

  const onClick = React.useCallback(
    // $FlowFixMe
    (evt: SyntheticEvent<HTMLDivElement>) => {
      evt.stopPropagation();
    },
    []
  );

  React.useEffect(() => {
    if (!audio || seeking) return undefined;
    const onTimeUpdate = throttleFn(() => {
      setTimerPosition((audio.currentTime || 0) / audio.duration);
    }, 500);
    // $FlowFixMe - 'timeupdate' is not a recognised event type
    audio.addEventListener('timeupdate', onTimeUpdate);
    return () => {
      onTimeUpdate.cancelThrottle();
      // $FlowFixMe - 'timeupdate' is not a recognised event type
      audio.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [ audio, seeking, ]);

  React.useEffect(() => {
    if (handlingClick) {
      setTimeout(() => setHandlingClick(false), 110);
    }
  }, [ handlingClick, ]);

  React.useEffect(() => {
    const handleBarResize = throttleFn(() => {
      if (wrapperRef.current) {
        setBarWidth(wrapperRef.current.getBoundingClientRect().width);
      }
    }, 500);
    window.addEventListener('resize', handleBarResize);

    // Initial setting. By design wrapperRef will be populated by now.
    wrapperRef.current && setBarWidth(wrapperRef.current.getBoundingClientRect().width);

    return () => {
      handleBarResize.cancelThrottle();
      window.removeEventListener('resize', handleBarResize);
    };
  }, []);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <div
      // $FlowFixMe
      ref={wrapperRef}
      onClick={onBarClick}
      className={css(wrapperStyle)}
    >
      <div className={css(railStyle)}>
        <div
          aria-hidden
          className={css(progressIndicatorStyle)}
          style={{ transform: `scaleX(${timerPosition})`, }}
        />
      </div>
      <div
        role="slider"
        tabIndex="-1"
        aria-valuemin={0}
        aria-valuemax={displayedDuration}
        aria-valuenow={ariaValueNow}
        aria-valuetext={ariaValueText}
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        onKeyDown={onKeyDown}
        onClick={onClick}
        className={css(sliderClickAreaStyle)}
        style={{ transform: `translateX(${timerPosition * barWidth}px)`, }}
        data-test="slider"
      >
        {/* This div should take focus when the button is clicked,
        so that the button's focus styles won't apply upon click, but will apply when tabbing */}
        <div
          tabIndex={-1}
          className={css(sliderOuterStyle)}
        >
          <div className={css(sliderInnerStyle)} />
        </div>
      </div>
    </div>
  );
}

export default AudioProgressController;

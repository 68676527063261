/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import useAudioPlayerContext from '../useAudioPlayerContext';

type MockDataType = {
  min: number,
  max: number,
  inc: number,
  direction: number,
  current: number,
};

type MockDataArrayType = Array<MockDataType>

const randomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const mappedValue = ([ minRange, maxRange, ]) => ([ minDomain, maxDomain, ]) => {
  const range = maxRange - minRange;
  const domain = maxDomain - minDomain;
  const f = v => minRange + ((v - minDomain) * range) / domain;
  return val => (val > maxDomain ? maxRange : val < minDomain ? minRange : f(val));
};

const createMockDataArrayGetter = ({
  minRange = [ 145, 195, ],
  maxRange = [ 205, 255, ],
  incDividerRange = [ 20, 40, ],
} = {}) => {
  const directions = [ -1, 1, ];
  const generateMockData = (): MockDataType => {
    const [ min, max, ] = [ randomInt(...minRange), randomInt(...maxRange), ];
    return {
      min,
      max,
      inc: (max - min) / randomInt(...incDividerRange),
      direction: directions[randomInt(0, 2)],
      current: randomInt(min, max),
    };
  };
  const createMockDataArray = (size: number): MockDataArrayType => Array(size)
    .fill()
    .map(generateMockData);

  let mockDataArray;
  return (size: number): MockDataArrayType => {
    if (!mockDataArray) {
      mockDataArray = createMockDataArray(size);
    }
    else if (mockDataArray.length < size) {
      const dataToAppend = createMockDataArray(size - mockDataArray.length);
      mockDataArray = mockDataArray.concat(dataToAppend);
    }
    else if (mockDataArray.length > size) {
      mockDataArray = mockDataArray.slice(0, size);
    }
    return mockDataArray;
  };
};

/* eslint-disable no-param-reassign */
const processMockDataArray = (mockDataArray: MockDataArrayType, rate: number) => {
  rate = rate > 0 ? rate : 1;
  mockDataArray.forEach(data => {
    const { min, max, inc, current, } = data;
    let { direction, } = data;
    let next = direction > 0 ? current + inc / (1 / rate) : current - inc / (1 / rate);
    if (direction < 0 && next < min) {
      next = min;
      direction = 1;
    }
    else if (direction > 0 && next > max) {
      next = max;
      direction = -1;
    }
    data.current = next;
    data.direction = direction;
  });
};
/* eslint-enable no-param-reassign */

const BAR_WIDTH = 5;
const BASE_GAP = 1.5;

// All analyser node code is currently commented out, since AudioContext combined with an audio element
// is not currently reliable enough outside of Chrome.
function AudioFrequencyBars() {
  const { audio, isPlaying, isBuffering, initialState, } = useAudioPlayerContext();
  const { css, theme, } = useFela();
  const [ width, setWidth, ] = React.useState(0);
  const ref = React.useRef(null);
  const getMockDataArray = React.useMemo(() => createMockDataArrayGetter(), []);

  React.useEffect(() => {
    const handleResize = () => {
      if (!ref.current) return;
      const canvas = ref.current;
      const parent = canvas.parentElement;
      const { width: parentWidth, } = parent.getBoundingClientRect();
      if (parentWidth !== canvas.width) {
        setWidth(parentWidth);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    /* eslint-disable no-param-reassign */
    if (!ref.current || !width) return undefined;
    // const fftSize = width < 600 ? 512 : 1024;
    const canvas = ref.current;
    const { height, } = canvas;
    const ctx = canvas.getContext('2d');
    const barColor = theme.color('audioPlayer', 'bg');
    const barWidth = 5;
    const barCount = Math.ceil(width / (BAR_WIDTH + BASE_GAP));
    const barGap = (width - barCount * BAR_WIDTH) / (barCount - 1);
    const barHeightScale = mappedValue([ 5, canvas.height, ])([ 0, 255, ]);
    // if (analyser) analyser.fftSize = fftSize;
    // const dataArray = analyser
    //   ? new Uint8Array(analyser.frequencyBinCount)
    //   : getMockDataArray(barCount);
    // const getValue = Array.isArray(dataArray) ? data => data.current : data => data;
    const dataArray = getMockDataArray(barCount);
    const getValue = data => data.current;

    let drawId;
    const draw = () => {
      // // analyser is not instantiated before the first click on the play button
      // if (analyser && !initialState) analyser.getByteFrequencyData(dataArray);
      if (!initialState) processMockDataArray(dataArray, audio ? audio.playbackRate : 1);

      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = barColor;
      let x = 0;
      for (let i = 0; i < barCount; i += 1) {
        const barHeight = barHeightScale(getValue(dataArray[i]));
        ctx.fillRect(x, height - barHeight, barWidth, barHeight);
        x += barWidth + barGap;
      }
      if (isPlaying && !isBuffering) drawId = window.requestAnimationFrame(draw);
    };
    draw();
    return () => {
      window.cancelAnimationFrame(drawId);
    };
    /* eslint-enable no-param-reassign */
  }, [
    // analyser,
    isPlaying,
    isBuffering,
    width,
    initialState,
    getMockDataArray,
    audio,
    theme,
  ]);

  return (
    <canvas
      // $FlowFixMe
      ref={ref}
      className={css({
        display: 'block',
      })}
      width={width}
      height={70}
    />
  );
}

export default AudioFrequencyBars;

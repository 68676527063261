/* global localStorage fetch */
import { writeToLocalStorage, } from '../../../utils/writeToStorage';

const EVENT_STORAGE_KEY = '__mnyCnsmptn';

export const omnyEventTypes = Object.freeze({ stop: 'Stop', start: 'Start', });

export const getOmnyEventsFromStorage = () => {
  let events;
  try {
    events = JSON.parse(localStorage.getItem(EVENT_STORAGE_KEY)) || {};
  }
  catch (err) {
    events = {};
  }
  return events;
};

export const writeOmnyEventsToStorage = events => {
  writeToLocalStorage({ key: EVENT_STORAGE_KEY, value: JSON.stringify(events), });
};

export const removeOmnyEventsFromStorage = () => {
  try {
    localStorage.removeItem(EVENT_STORAGE_KEY);
  }
  catch (err) {
    return false;
  }
  return true;
};

export const createConsumptionEventFactory = (OrganizationId, ClipId, SessionId) => {
  let SeqNumber = 0;
  const createEvent = (Type, Position) => ({
    Type,
    Position,
    SeqNumber,
    ClipId,
    SessionId,
    OrganizationId,
    Timestamp: Math.floor(Date.now() / 1000),
  });
  return (Type, Position) => {
    SeqNumber += 1;
    return createEvent(Type, Position);
  };
};

export const postOmnySession = Events => fetch(
  `https://traffic.omny.fm/api/consumption/events?organizationId=${(Events[0] || {}).OrganizationId}`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      Source: 'Web',
      Completed: true,
      Events,
    }),
  }
)
  .then(response => response.json())
  .then(data => data.Enabled);

// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import AudioFrequencyBars from './audioPlayerElements/AudioFrequencyBars';
import AudioProgressController from './audioPlayerElements/AudioProgressController';
import PlayButton from './audioPlayerElements/PlayButton';
import SkipButton from './audioPlayerElements/SkipButton';
import PlaybackRateButton from './audioPlayerElements/PlaybackRateButton';
import AudioElapsedTime from './audioPlayerElements/AudioElapsedTime';
import AudioDuration from './audioPlayerElements/AudioDuration';

const playerControllerWrapperStyle = ({ theme, }) => ({
  backgroundColor: theme.color('audioPlayer', 'bg'),
  paddingStart: '1rem',
  paddingEnd: '1rem',
  paddingTop: '1rem',
  display: 'grid',
  gridRowGap: '1rem',
  gridTemplateColumns: '1fr auto 1fr',
  color: theme.color('audioPlayer', 'color'),
  fontWeight: 'bold',
  ...theme.type(-2),
  ...theme.mq(
    { until: 's', },
    {
      paddingBottom: '1rem',
      gridTemplateAreas: `
"progress progress progress"
"duration controls elapsed"
".        rate    ."
`,
    }
  ),
  ...theme.mq(
    { from: 's', },
    {
      paddingBottom: '2rem',
      gridTemplateAreas: `
"progress progress progress"
"duration controls elapsed"
"rate    controls ."
`,
    }
  ),
});

function HtzAudioPlayerDefaultSkin() {
  const { css, } = useFela();

  return (
    <>
      <AudioFrequencyBars />
      <div className={css(playerControllerWrapperStyle)}>
        <AudioProgressController />
        <div
          className={css({
            gridArea: 'controls',
            display: 'flex',
            alignSelf: 'center',
          })}
        >
          <SkipButton skipBy={15} />
          <PlayButton
            miscStyles={{
              marginInlineStart: '5rem',
              marginInlineEnd: '5rem',
            }}
          />
          <SkipButton skipBy={-15} />
        </div>
        <PlaybackRateButton
          miscStyles={{
            justifySelf: [ { from: 's', value: 'start', }, ],
          }}
        />
        <AudioElapsedTime />
        <AudioDuration />
      </div>
    </>
  );
}

export default HtzAudioPlayerDefaultSkin;

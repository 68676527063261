/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import { audioPlayerContext, } from './useAudioPlayerContext';
import { loadStates, } from './audioPlayerConsts';
import { formatTime, } from './audioPlayerUtils';
import HtzAudioPlayerDefaultSkin from './HtzAudioPlayerDefaultSkin';
import throttleFn from '../../utils/throttleFn';

type skipCB = ({
  from: number,
  to: number,
  isPlaying: boolean,
  duration: number,
  byKeyboard: boolean,
}) => void;
type progressBarCB = ({ from: number, to: number, isPlaying: boolean, duration: number, }) => void;
type playPauseCB = ({ pos: number, duration: number, }) => void;
type playbacEndCB = ({ pos: number, }) => void;
type playbackRateChangeCB = ({ from: number, to: number, }) => void;
type unmountCB = ({
  pos: number,
  isPlaying: boolean,
  duration: number,
  isUnloading: boolean,
}) => void;

type AudioPlayerProps = {
  fileUrl: string,
  loadAfterFirstClick: ?boolean,
  onSkip?: ?skipCB,
  onScrub?: ?progressBarCB,
  onProgressBarClick?: ?progressBarCB,
  onPlay?: ?playPauseCB,
  onPause?: ?playPauseCB,
  onPlaybackEnd?: ?playbacEndCB,
  onPlaybackRateChange?: ?playbackRateChangeCB,
  onUnmount?: ?unmountCB,
  miscStyles?: ?StyleProps,
  wrapperRef?: ?({ current: null | HTMLDivElement, } | (?HTMLDivElement) => void),

  /**
   * Children should include audio player elements (a play button, at the very least).
   * Player elements may be imported from the `audioPlayerElements` folder, or from the index.js file.
   * If need be, it should be possible to implement custom player elements by accessing
   * the player context via `useAudioPlayerContext`.
   *
   * When rendering the audio player component without any children, the default player skin will be used.
   */
  children: ?React.Node,
};

const mainWrapperStyles = ({ theme, miscStyles, }) => ({
  direction: 'rtl',
  fontFamily: theme.fontStacks[theme.audioPlayerStyle.fontStack],
  userSelect: 'none',
  '-webkit-tap-highlight-color': 'transparent',
  extend: [
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

const nextPlaybackRateMap = new Map([
  [ 1, 1.25, ],
  [ 1.25, 1.5, ],
  [ 1.5, 1.75, ],
  [ 1.75, 2, ],
  [ 2, 0.5, ],
  [ 0.5, 0.75, ],
  [ 0.75, 1, ],
]);

// TODO: Remove next line after adding captions
/* eslint-disable jsx-a11y/media-has-caption */
function HtzAudioPlayer({
  fileUrl,
  loadAfterFirstClick,
  onSkip,
  onPlay,
  onPause,
  onScrub,
  onProgressBarClick,
  onPlaybackEnd,
  onUnmount,
  onPlaybackRateChange,
  wrapperRef,
  miscStyles,
  children,
}: AudioPlayerProps): React.Node {
  // using refs to make sure that the 'unMounting' useEffect
  // and its cleaning function would only run at the appropriate times
  // to simulate componentDidMount/componentWillUnmount behaviour.
  const audioRef = React.useRef(null);
  const onUnmountRef = React.useRef(onUnmount);

  const [ loadState, setLoadState, ] = React.useState(loadStates.notLoading);
  const [ audio, setAudio, ] = React.useState(null);
  const [ busy, setBusy, ] = React.useState(false);
  // const [ analyser, setAnalyser, ] = React.useState(null);
  const [ currentTime, setCurrentTime, ] = React.useState(0);
  const [ playbackRate, setPlaybackRate, ] = React.useState(1);
  const [ isBuffering, setIsBuffering, ] = React.useState(false);
  const [ isPlaying, setIsPlaying, ] = React.useState(false);
  const [ isMuted, setIsMuted, ] = React.useState(false);
  const [ initialState, setInitialState, ] = React.useState(true);
  const [ duration, setDuration, ] = React.useState(-1);

  const { css, } = useFela({ miscStyles, });

  const displayedDuration = React.useMemo(() => formatTime(duration), [ duration, ]);
  const timeElapsed = formatTime(currentTime);

  const refCallback = React.useCallback(audioEl => {
    if (audioEl) {
      if (loadAfterFirstClick) {
        setLoadState(loadStates.waitingForPlayReq);
      }
      else {
        // eslint-disable-next-line no-param-reassign
        audioEl.src = fileUrl;
        audioEl.load();
        setLoadState(loadStates.loading);
      }
    }
    audioRef.current = audioEl;
    setAudio(audioEl);
  }, [ loadAfterFirstClick, fileUrl, ]);

  // const setupAnalyser = React.useCallback(() => {
  //   if (!audio) return;
  //   if ('AudioContext' in window) {
  //     const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  //     const source = audioCtx.createMediaElementSource(audio);
  //     const analyser = audioCtx.createAnalyser();
  //     source.connect(analyser).connect(audioCtx.destination);
  //     setAnalyser(analyser);
  //   }
  // }, [ audio, ]);

  const onPlayButtonClick = React.useCallback(async () => {
    if (!audio || busy || loadState === loadState.loading) return;
    // if (!analyser && initialState) setupAnalyser();
    if (isPlaying) {
      audio.pause();
      onPause && onPause({ pos: audio.currentTime, duration: audio.duration, });
    }
    else {
      setIsBuffering(true);
      if (loadState === loadStates.waitingForPlayReq) {
        // setting the src in this context so that the `play` method invocation
        // would not be rejected in iOS devices.
        // (the method has to be invoked within the context of the user interaction)
        audio.src = fileUrl;
        audio.load();
        setLoadState(loadStates.loading);
      }
      setBusy(true);
      setIsPlaying(true);
      await audio.play();
      setBusy(false);
      onPlay && onPlay({ pos: audio.currentTime, duration: audio.duration, });
    }
  }, [ audio, isPlaying, busy, loadState, fileUrl, onPause, onPlay, ]);

  const skip = React.useCallback(
    (secs = 15, byKeyboard = false) => {
      if (!audio) return;
      setIsBuffering(true);
      const from = audio.currentTime;
      audio.currentTime = Math[secs > 0 ? 'min' : 'max'](
        audio.currentTime + secs,
        secs > 0 ? audio.duration : 0
      );
      const to = audio.currentTime;

      onSkip && onSkip({ from, to, isPlaying, byKeyboard, duration: audio.duration, });
    },
    [ audio, isPlaying, onSkip, ]
  );

  const toggleMute = React.useCallback(() => {
    if (audio) {
      setIsMuted(audio.muted = !audio.muted);
    }
  }, [ audio, ]);

  const getNextPlaybackRate = React.useCallback(() => {
    if (!audio) return 1;
    return nextPlaybackRateMap.get(audio.playbackRate) || 1;
  }, [ audio, ]);

  const onPlaybackRateChangeRequested = React.useCallback(() => {
    if (!audio || audio.readyState === 0) return;
    // $FlowFixMe
    const nextRate = getNextPlaybackRate();
    const prevRate = audio.playbackRate;
    setPlaybackRate((audio.playbackRate = nextRate));
    onPlaybackRateChange && onPlaybackRateChange({ from: prevRate, to: nextRate, });
  }, [ audio, getNextPlaybackRate, onPlaybackRateChange, ]);

  const onLoadedData = React.useCallback(() => setLoadState(loadStates.loaded), []);

  const onTimeUpdate = React.useMemo(
    () => audio
      && throttleFn(() => {
        setCurrentTime(audio.currentTime);
      }, 100),
    [ audio, ]
  );

  const onPlaying = React.useCallback(() => {
    setIsBuffering(false);
    setInitialState(false);
  }, []);

  const onPauseNative = React.useCallback(() => {
    if (!audio) return;
    setIsPlaying(false);
    const playbackEnded = audio.duration <= audio.currentTime;
    const atTheStart = audio.currentTime === 0;
    if (playbackEnded || atTheStart) {
      setInitialState(true);
      if (playbackEnded && onPlaybackEnd) {
        onPlaybackEnd({ pos: audio.currentTime, duration: audio.duration, });
      }
    }
  }, [ audio, onPlaybackEnd, ]);

  const onDurationChange = React.useCallback(
    // $FlowFixMe
    (evt: SyntheticEvent<HTMLAudioElement>) => {
      setDuration(evt.currentTarget.duration || 0);
    },
    []
  );

  const onSeeking = React.useCallback(() => setIsBuffering(true), []);

  const onSeeked = React.useCallback(() => setIsBuffering(false), []);

  React.useEffect(() => {
    onUnmountRef.current = onUnmount;
  }, [ onUnmount, ]);

  // handling unmounting of the component or unloading of the document
  React.useEffect(() => {
    let invoked = false;
    const beforeDestroyed = evt => {
      const audio = audioRef.current;
      const onUnmount = onUnmountRef.current;
      if (audio && onUnmount && !invoked) {
        onUnmount({
          pos: audio.currentTime,
          duration: audio.duration,
          isPlaying: !audio.paused,
          isUnloading: evt.type !== 'willUnmount',
        });
        invoked = true;
      }
      /* eslint-disable no-param-reassign */
      // $FlowFixMe
      if ('returnValue' in evt) delete evt.returnValue;
      /* eslint-enable no-param-reassign */
    };
    window.addEventListener('beforeunload', beforeDestroyed);
    window.addEventListener('pagehide', beforeDestroyed);
    return () => {
      beforeDestroyed({ type: 'willUnmount', });
      window.removeEventListener('beforeunload', beforeDestroyed);
      window.removeEventListener('pagehide', beforeDestroyed);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      className={css(mainWrapperStyles)}
      data-test="audioPlayer"
    >
      <audio
        ref={refCallback}
        /**
        * Removing this attribute for now, due to the weirdest bug in Safari 13.1.
        * A bug which seems to only affect our production sites (haaretz.co.il/themarker.com...)
        * and not any other sites (e.g nytimes, ynet, guardian, codepen, pre.haaretz.co.il, etc).
        * When requesting an mp3 from Omny, some headers go missing along the way, and Safari
        * interprets the file as a "Live Broadcast" stream that is just an endless loop of the
        * first couple of minutes of audio.
        * Hopefully, this won't result in browsers blocking requests for certain audio files 🤞.
        */
        // crossOrigin="anonymous"
        onLoadedData={onLoadedData}
        onTimeUpdate={onTimeUpdate}
        onPlaying={onPlaying}
        onPause={onPauseNative}
        onDurationChange={onDurationChange}
        onSeeking={onSeeking}
        onSeeked={onSeeked}
      />
      <audioPlayerContext.Provider
        value={{
          audio,
          loadState,
          currentTime,
          timeElapsed,
          playbackRate,
          isBuffering,
          isPlaying,
          isMuted,
          initialState,
          displayedDuration,

          skip,
          onScrub,
          onProgressBarClick,
          onPlayButtonClick,
          onPlaybackRateChangeRequested,
          getNextPlaybackRate,
          toggleMute,
        }}
      >
        {children || <HtzAudioPlayerDefaultSkin />}
      </audioPlayerContext.Provider>
    </div>
  );
}

HtzAudioPlayer.defaultProps = {
  loadAfterFirstClick: false,
  onSkip: null,
  onScrub: null,
  onProgressBarClick: null,
  onPlay: null,
  onPause: null,
  onPlaybackEnd: null,
  onPlaybackRateChange: null,
  onUnmount: null,
  miscStyles: null,
  wrapperRef: null,
};

export default HtzAudioPlayer;

/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, type StyleProps, } from '@haaretz/htz-css-tools';
import useAudioPlayerContext from '../useAudioPlayerContext';

type AudioElapsedTimeProps = {
  miscStyles: ?StyleProps,
  disableGridArea: boolean,
};

AudioElapsedTime.defaultProps = {
  miscStyles: null,
  disableGridArea: false,
};

function AudioElapsedTime({ miscStyles, disableGridArea, }: AudioElapsedTimeProps): React.Node {
  const { timeElapsed, } = useAudioPlayerContext();
  const { css, theme, } = useFela();

  return (
    <div
      className={css({
        gridArea: disableGridArea ? null : 'elapsed',
        justifySelf: 'end',
        lineHeight: 1,
        extend: [
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
      data-test="elapsedTime"
    >
      {timeElapsed}
    </div>
  );
}

export default AudioElapsedTime;

// @flow
import * as React from 'react';
import uuid from 'uuid/v4';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import AudioPlayerActionsProvider from '../AudioPlayerActionsProvider';
import {
  createConsumptionEventFactory,
  getOmnyEventsFromStorage,
  writeOmnyEventsToStorage,
  postOmnySession,
  omnyEventTypes,
} from './omnyUtils';

type OmnyPodcastProps = {
  fileUrl: string,
  loadAfterFirstClick: ?boolean,
  podcastBIAction: (string, ?Object) => void,
  miscStyles: ?StyleProps,
  wrapperRef?: ?({ current: null | HTMLDivElement, } | (?HTMLDivElement) => void),
  children: ?React.Node,
};

type onSkipArgType = { from: number, to: number, isPlaying: boolean, };

type onPlayPauseArgType = { pos: number, };

type onUnmountArgType = { pos: number, isPlaying: boolean, isUnloading: boolean, };

// clipUrl should end with the following pattern:
// /${organization_id(uuid)}/${program_id(uuid)}/${episode_id(uuid)}/audio.mp3
const orgAndClipIdRegex = /\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})\/audio.mp3/;

OmnyPodcast.defaultProps = {
  // `true` by default so that Omny will not record every page entry in their analytics
  loadAfterFirstClick: true,
  miscStyles: null,
  wrapperRef: null,
};

function OmnyPodcast({
  fileUrl,
  loadAfterFirstClick,
  podcastBIAction,
  miscStyles,
  wrapperRef,
  children,
}: OmnyPodcastProps): React.Node {
  const [ sessionId, setSessionId, ] = React.useState(uuid());

  const [ orgId, clipId, ] = React.useMemo(() => {
    const match = fileUrl.match(orgAndClipIdRegex);
    return match ? [ match[1], match[2], ] : [];
  }, [ fileUrl, ]);

  const {
    addConsumptionEvent,
    events,
    saveEventsInStorage,
  } = React.useMemo(
    () => {
      const createConsumptionEvent = createConsumptionEventFactory(
        orgId,
        clipId,
        sessionId
      );
      const events = [];
      const saveEventsInStorage = () => {
        const allEvents = getOmnyEventsFromStorage();
        allEvents[sessionId] = events;
        writeOmnyEventsToStorage(allEvents);
      };
      const addConsumptionEvent = (type, pos) => events.push(createConsumptionEvent(type, pos));
      return {
        addConsumptionEvent,
        events,
        saveEventsInStorage,
      };
    },
    [ orgId, clipId, sessionId, ]
  );

  const onPlay = React.useCallback(
    // returns true if this is the start of an Omny defined session.
    // used in the biAction's additional info
    // $FlowFixMe
    ({ pos, duration, }: onPlayPauseArgType) => {
      const eventCount = addConsumptionEvent(omnyEventTypes.start, pos);
      return eventCount === 1;
    },
    [ addConsumptionEvent, ]
  );

  const onPause = React.useCallback(
    // $FlowFixMe
    ({ pos, duration, }: onPlayPauseArgType) => {
      addConsumptionEvent(omnyEventTypes.stop, pos);
    },
    [ addConsumptionEvent, ]
  );

  const handleOmnyDataOnSkip = React.useCallback(
    // $FlowFixMe
    ({ from, to, isPlaying, }: onSkipArgType) => {
      if (isPlaying) {
        addConsumptionEvent(omnyEventTypes.stop, from);
        addConsumptionEvent(omnyEventTypes.start, to);
      }
    },
    [ addConsumptionEvent, ]
  );

  const onSkip = React.useCallback(
    // $FlowFixMe
    ({ from, to, duration, isPlaying, byKeyboard, }) => {
      handleOmnyDataOnSkip({ from, to, isPlaying, });
    },
    [ handleOmnyDataOnSkip, ]
  );

  const onScrub = React.useCallback(
    // $FlowFixMe
    ({ from, to, duration, isPlaying, }: onSkipArgType) => {
      handleOmnyDataOnSkip({ from, to, isPlaying, });
    },
    [ handleOmnyDataOnSkip, ]
  );

  const onProgressBarClick = React.useCallback(
    // $FlowFixMe
    ({ from, to, duration, isPlaying, }: onSkipArgType) => {
      handleOmnyDataOnSkip({ from, to, isPlaying, });
    },
    [ handleOmnyDataOnSkip, ]
  );

  const onPlaybackEnd = React.useCallback(
    // $FlowFixMe
    ({ pos, }: onPlayPauseArgType) => {
      addConsumptionEvent(omnyEventTypes.stop, pos);

      postOmnySession(events)
        .catch(saveEventsInStorage);

      setSessionId(uuid());
    },
    [ addConsumptionEvent, events, saveEventsInStorage, ]
  );

  const onUnmount = React.useCallback(
    // $FlowFixMe
    ({ pos, duration, isPlaying, isUnloading, }: onUnmountArgType) => {
      if (events.length === 0) return;
      if (isPlaying) addConsumptionEvent(omnyEventTypes.stop, pos);

      if (isUnloading) {
        saveEventsInStorage();
      }
      else {
        postOmnySession(events)
          .catch(saveEventsInStorage);

        setSessionId(uuid());
      }
    },
    [ addConsumptionEvent, events, saveEventsInStorage, ]
  );

  return (
    <AudioPlayerActionsProvider
      isOmny
      fileUrl={fileUrl}
      loadAfterFirstClick={loadAfterFirstClick}
      podcastBIAction={podcastBIAction}
      omnyOnSkip={onSkip}
      // $FlowFixMe - Flow thinks `onPlay` is a boolean rather than a function
      omnyOnPlay={onPlay}
      omnyOnPause={onPause}
      omnyOnScrub={onScrub}
      omnyOnProgressBarClick={onProgressBarClick}
      omnyOnPlaybackEnd={onPlaybackEnd}
      omnyOnUnmount={onUnmount}
      miscStyles={miscStyles}
      wrapperRef={wrapperRef}
    >
      {children}
    </AudioPlayerActionsProvider>
  );
}

export default OmnyPodcast;
